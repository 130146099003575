.datePicker,
.datePicker * {
	padding: 4px !important;
	padding-right: 5px !important;
}

.dateCol {
	display: flex;
	justify-content: center !important;
	align-items: center !important;
}
