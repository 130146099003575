body,
html {
	margin: 0;
	font-family: "Roboto", sans-serif;
	font-size: 16px; /* Optional: Set a base font size */
	line-height: 1.5; /* Optional: Adjust line-height for readability */
	-moz-osx-font-smoothing: grayscale;
	color: #333; /* Optional: Set a base text color */
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
#root {
	padding: 7px;
}

.btn-primary {
	color: white;
	background-color: #8faadc !important;
	border: none;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 8px;
}

.btn-primary:hover {
	background-color: #e9ecef;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.override-button {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 7rem;
	height: 2.5rem;
	background-color: #8faadc;
	color: white;
	border: none;
	cursor: pointer;
	overflow: hidden;
}

.override-button:disabled,
.override-button[aria-disabled="true"] {
	background-color: #b0b0b0 !important;
	color: #ffffff !important;
	cursor: not-allowed;
	opacity: 0.6;
	pointer-events: none;
}

.button-text {
	position: absolute;
	opacity: 1;
	transition: opacity 0.2s ease-in-out;
}

.status-text {
	position: absolute;
	opacity: 0;
	transition: opacity 0.2s ease-in-out;
}

.override-button:hover .button-text {
	opacity: 0;
}

.override-button:hover .status-text {
	opacity: 1;
}

.override-button:hover[data-status="Processing"] {
	background-color: rgb(64, 212, 69) !important;
}

.override-button:hover[data-status="Failed"] {
	background-color: rgb(248, 87, 87) !important;
}

.override-button:hover[data-status="Pending"] {
	background-color: rgb(242, 242, 240) !important;
	color: #333;
}

.override-button:hover[data-status="Processing"] .status-text {
	display: none;
}

.override-button:hover[data-status="Processing"] .loader {
	display: inline-block;
	position: absolute;
	width: 16px;
	height: 16px;
	border: 2px solid white;
	border-top: 2px solid transparent;
	border-radius: 50%;
	animation: spin 0.6s linear infinite;
}

.loader {
	display: none;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.dropdown-toggle {
	background-color: transparent !important;
	color: #8faadc !important;
}

.modal-header {
	display: flex !important;
	flex-shrink: 11 !important;
	justify-content: space-around !important;
	align-items: center !important;
	width: 100% !important;
	padding: 0px 9px 0px 8px !important;
	background-color: #d9d9d9 !important;
	border: none !important;
}

.modal-header-lcontent {
	display: flex !important;
	flex-shrink: 11 !important;
	align-items: center !important;
	width: 100% !important;
	padding: 0px 9px 0px 8px !important;
	background-color: #d9d9d9 !important;
	border: none !important;
}
.nav-link {
	border-radius: 10px 10px 0px 0px !important;
	color: rgb(106, 106, 106) !important;
}

.tab-link {
	color: rgb(76, 74, 74) !important;
	text-decoration: none;
}

.tab-link.active {
	background-color: #d9d9d9 !important;
	color: rgb(78, 75, 75) !important;
	text-decoration: none;
}

.form-check-input:checked {
	background-color: #8faadc;
	border: none;
}

.customForm {
	display: block !important;
	width: 100% !important;
	padding: 0.375rem 0.75rem !important;
	font-size: 1rem !important;
	font-weight: 400 !important;
	line-height: 1.5 !important;
	color: var(--bs-body-color) !important;
	border: none !important;
	appearance: none !important;
	background-color: var(--bs-body-bg) !important;
	background-clip: padding-box !important;
	transition:
		border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out !important;
	box-shadow: none !important;
}

.nav-link-opposite {
	background-color: #d9d9d9 !important;
	border-radius: 10px 10px 0px 0px !important;
}
.nav-link-opposite.active {
	background-color: #ffffff !important;
	border-radius: 10px 10px 0px 0px !important;
}

.fixed-bottom-center {
	position: fixed;
	bottom: 10px; /* Stick to the bottom */
	left: 50%; /* Move the element to the horizontal center */
	transform: translateX(-50%); /* Adjust the element to be perfectly centered */
	z-index: 1000; /* Ensure it stays on top of other elements, adjust as necessary */
}

.button-quick-select {
	cursor: pointer;
	background-color: #7ddcf98d;
	border: none;
	border-radius: 20px;
	color: #605f5f !important;
}

.button-quick-select:hover {
	background-color: #7ddcf9fb;
}

.table-responsive {
	height: -webkit-fill-available;
}

.replaceOrder {
	color: grey;
}
/* .fade.modal.show {
	backdrop-filter: blur(2px);
} */

.accordion-body {
	padding: 0 !important;
}
.accordion-header {
	margin: 0 !important;
}

.accordion-item:nth-child(odd) .accordion-button {
	background-color: #f2f2f2 !important;
}

tr:hover td {
	background-color: #b3d4ff90 !important;
}
tr[data-Active="true"] td {
	background-color: #b3d4ff90 !important;
}

.modal-content main {
	min-height: 30rem !important;
}

.table-header th {
	text-align: center;
}
.table-data td {
	text-align: center;
}

.sort {
	color: #d9d9d9;
	margin-inline: 2px;
}

.sort.active {
	color: #433f3f;
}

.custom-popover-date {
	width: 40rem !important;
	max-width: 100%;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
